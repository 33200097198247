import { useSelector } from "react-redux";
import { EMPLOYEES_TOPIC, ESTABLISHMENT_TOPIC, SALARY_TOPIC, CENTIV_TOPIC } from "../../pages/requests/helper";

export const ACL_MODULES = {

  DASHBOARD: 'DASHBOARD',
  MANAGE_USERS: 'MANAGE_USERS',
  EMPLOYEES: 'EMPLOYEES',
  EMPLOYEE: 'EMPLOYEE',
  CENTIV_CARD: 'CENTIV_CARD',
  TRANSACTIONS: 'TRANSACTIONS',
  REQUESTS: 'REQUESTS',
  // ADVANCE_SALARY: 'ADVANCE_SALARY',
  SALARY_STATUS: 'SALARY_STATUS',
  PROCESS_SALARIES: 'PROCESS_SALARIES',
  ESTABLISHMENT: 'ESTABLISHMENT',
  MY_OTHER_COMPANIES: 'MY_OTHER_COMPANIES',
  CENTIV_CARD_EMPLOYEE: 'CENTIV_CARD_EMPLOYEE',
  REPLACEMENT_CARDS: 'REPLACEMENT_CARDS'

};

export const ACL_ACCESS_LIST = {
  VIEW: 'VIEW',
  ADD: 'ADD',
  CREATE: 'CREATE',
  MAKER: 'MAKER',
  CHECKER: 'CHECKER',
  USER_FINAL_CHECKER: 'USER_FINAL_CHECKER',
  ALL_REQUEST_CHECKER: 'ALL_REQUEST_CHECKER',
  REQUEST_CHECKER: 'REQUEST_CHECKER',
  REQUEST_AUTHORIZER: 'REQUEST_AUTHORIZER',
  ALL_REQUEST_AUTHORIZER: 'ALL_REQUEST_AUTHORIZER',
  CAN_PROCESS: 'CAN_PROCESS',
  ESTABLISHMENT: 'ESTABLISHMENT',
  CREATE_USER: 'CREATE_USER',
  EDIT_USER: 'EDIT_USER',
  MANAGE_ACCESS_USER: 'MANAGE_ACCESS_USER',
  ACTIVATE_DEACTIVATE_USER: 'ACTIVATE_DEACTIVATE_USER',
  ADD_ESTABLISHMENT: 'ADD_ESTABLISHMENT',
  ADD_EMPLOYEES: 'ADD_EMPLOYEES',
  DEACTIVATE_EMPLOYEES: 'DEACTIVATE_EMPLOYEES',
  EXPORT_EMPLOYEES: 'EXPORT_EMPLOYEES',
  ADD_OTHER_BANK_DETAILS: 'ADD_OTHER_BANK_DETAILS',
  EDIT_OTHER_BANK_DETAILS: 'EDIT_OTHER_BANK_DETAILS',
  ADD_KAMELPAY_DETAILS: 'ADD_KAMELPAY_DETAILS',
  REPLACE_CARD: 'REPLACE_CARD',
  STATEMENT_REQUEST: 'STATEMENT_REQUEST',
  ADD_UPDATE_EID: 'ADD_UPDATE_EID',
  EDIT_PERSONAL_DETAIL: 'EDIT_PERSONAL_DETAIL',
  CHECK_CARD_STATUS: 'CHECK_CARD_STATUS',
  CHECK_SUBSCRIPTION_STATUS: 'CHECK_SUBSCRIPTION_STATUS',
  LOAD_FUND: 'LOAD_FUND',
  VIEW_DETAIL: 'VIEW_DETAIL',
  UPLOAD_SALARY: 'UPLOAD_SALARY',
  UPLOAD_TRANSFER_RECEIPT: 'UPLOAD_TRANSFER_RECEIPT',
  CAN_DOWNLOAD: 'CAN_DOWNLOAD',
  CHANGE_PIN: 'CHANGE_PIN',
  CENTIV_DETAIL_VIEW: 'CENTIV_DETAIL_VIEW',
  EMPLOYEE_DETAIL_VIEW: 'EMPLOYEE_DETAIL_VIEW',
  ADD_UPDATE_PASSPORT: 'ADD_UPDATE_PASSPORT',
  ALLOW_GROUP_COMPANIES: 'ALLOW_GROUP_COMPANIES',
  CAN_BULK_REQUEST_APPROVE: 'CAN_BULK_REQUEST_APPROVE',
};

export const ACL_OPTIONS = [
  {
    key: ACL_MODULES.REQUESTS,
    access: [
      ACL_ACCESS_LIST.VIEW,
      // ACL_ACCESS_LIST.MAKER,
      ACL_ACCESS_LIST.ALL_REQUEST_CHECKER
    ]
  },
  {
    key: ACL_MODULES.DASHBOARD,
    access: [
      ACL_ACCESS_LIST.VIEW,
    ]
  },
  {
    key: ACL_MODULES.MANAGE_USERS,
    access: [
      ACL_ACCESS_LIST.VIEW,
      ACL_ACCESS_LIST.CREATE_USER,
      ACL_ACCESS_LIST.EDIT_USER,
      ACL_ACCESS_LIST.MANAGE_ACCESS_USER,
      ACL_ACCESS_LIST.ACTIVATE_DEACTIVATE_USER,
      ACL_ACCESS_LIST.USER_FINAL_CHECKER,
    ]
  },
  {
    key: ACL_MODULES.EMPLOYEES,
    access: [
      ACL_ACCESS_LIST.VIEW,
      ACL_ACCESS_LIST.ADD_EMPLOYEES,
      ACL_ACCESS_LIST.CAN_DOWNLOAD,
      ACL_ACCESS_LIST.DEACTIVATE_EMPLOYEES,
      ACL_ACCESS_LIST.REQUEST_CHECKER,
    ],
    subModules: [
      {
        key: ACL_MODULES.EMPLOYEE,
        access: [
          ACL_ACCESS_LIST.EMPLOYEE_DETAIL_VIEW,
          ACL_ACCESS_LIST.ADD_OTHER_BANK_DETAILS,
          ACL_ACCESS_LIST.EDIT_OTHER_BANK_DETAILS,
          ACL_ACCESS_LIST.ADD_KAMELPAY_DETAILS,
          ACL_ACCESS_LIST.REPLACE_CARD,
          ACL_ACCESS_LIST.STATEMENT_REQUEST,
          ACL_ACCESS_LIST.ADD_UPDATE_EID,
          ACL_ACCESS_LIST.EDIT_PERSONAL_DETAIL,
          ACL_ACCESS_LIST.CHECK_CARD_STATUS,
          ACL_ACCESS_LIST.CHECK_SUBSCRIPTION_STATUS,
          ACL_ACCESS_LIST.CHANGE_PIN,
          ACL_ACCESS_LIST.ADD_UPDATE_PASSPORT,
        ]
      }
    ]
  },
  {
    key: ACL_MODULES.CENTIV_CARD,
    access: [
      ACL_ACCESS_LIST.VIEW,
      ACL_ACCESS_LIST.LOAD_FUND,
      ACL_ACCESS_LIST.ADD_EMPLOYEES,
      ACL_ACCESS_LIST.UPLOAD_TRANSFER_RECEIPT,
      ACL_ACCESS_LIST.REQUEST_CHECKER,
    ],
    subModules: [
      {
        key: ACL_MODULES.CENTIV_CARD_EMPLOYEE,
        access: [
          ACL_ACCESS_LIST.CENTIV_DETAIL_VIEW,
          ACL_ACCESS_LIST.REPLACE_CARD,
          ACL_ACCESS_LIST.STATEMENT_REQUEST,
          ACL_ACCESS_LIST.ADD_UPDATE_EID,
          ACL_ACCESS_LIST.EDIT_PERSONAL_DETAIL,
          ACL_ACCESS_LIST.CHECK_CARD_STATUS,
          ACL_ACCESS_LIST.CHANGE_PIN,
        ],
      }
    ]
  },
  {
    key: ACL_MODULES.TRANSACTIONS,
    access: [
      ACL_ACCESS_LIST.VIEW,
      ACL_ACCESS_LIST.CAN_DOWNLOAD,
    ]
  },
  {
    key: ACL_MODULES.MY_OTHER_COMPANIES,
    access: [
      ACL_ACCESS_LIST.VIEW,
      ACL_ACCESS_LIST.ADD
    ]
  },
  {
    key: ACL_MODULES.SALARY_STATUS,
    access: [
      ACL_ACCESS_LIST.VIEW
    ]
  },
  {
    key: ACL_MODULES.REPLACEMENT_CARDS,
    access: [
      ACL_ACCESS_LIST.VIEW
    ]
  },
  {
    key: ACL_MODULES.ESTABLISHMENT,
    access: [
      ACL_ACCESS_LIST.VIEW,
      ACL_ACCESS_LIST.ADD_ESTABLISHMENT,
      ACL_ACCESS_LIST.REQUEST_CHECKER,
    ]
  },
  {
    key: ACL_MODULES.PROCESS_SALARIES,
    access: [
      ACL_ACCESS_LIST.VIEW,
      ACL_ACCESS_LIST.UPLOAD_SALARY,
      ACL_ACCESS_LIST.UPLOAD_TRANSFER_RECEIPT,
      ACL_ACCESS_LIST.REQUEST_CHECKER,
    ]
  },
];

export const modifyRequestSubModuleOrModuleConstants = [
  ACL_ACCESS_LIST.VIEW,
  ACL_ACCESS_LIST.REQUEST_CHECKER,
  ACL_ACCESS_LIST.REQUEST_AUTHORIZER,
  ACL_ACCESS_LIST.EMPLOYEE_DETAIL_VIEW,
  ACL_ACCESS_LIST.CENTIV_DETAIL_VIEW
];
export const IsAllowedOption = (key = '', role = "") => {
  const user = useSelector(({ auth }) => auth.user);
  if (user && Object.keys(user)?.length) {
    if (user?.isClient) return true;
    if (user?.accessRights && user?.accessRights?.length) {
      const parsedAccessRights = JSON.parse(user?.accessRights);
      if (!user?.isClient) {
        const getObj = Object.keys(parsedAccessRights).length ? parsedAccessRights?.find(d => d?.key === key) : null;
        if (getObj?.access && getObj?.access?.length) {
          return getObj?.access.some(access => access === role);
        } else {
          return false;
        }
      }
    }
  }
  return false;
};

let topicsClub = {
  [ACL_MODULES.EMPLOYEES]: EMPLOYEES_TOPIC,
  [ACL_MODULES.CENTIV_CARD]: CENTIV_TOPIC,
  [ACL_MODULES.ESTABLISHMENT]: ESTABLISHMENT_TOPIC,
  [ACL_MODULES.PROCESS_SALARIES]: SALARY_TOPIC,
};

function matchArrays(array1, array2) {
  return array1.filter(element => array2.some(element2 => element === element2?.topic));
}

export const getACLData = (user) => {
  let thirdStepTopics = user?.approvalverificationTracking?.threeStepVerification;
  let ACL_LIST = ACL_OPTIONS;
  ACL_LIST = ACL_LIST?.filter((obj) => {
    if (obj?.key === ACL_MODULES?.PROCESS_SALARIES) {
      return (user?.client?.viewAccess?.salaryProcess || user?.viewAccess?.salaryProcess)
    } else if (obj?.key === ACL_MODULES?.CENTIV_CARD) {
      return (user?.client?.viewAccess?.centivCard || user?.viewAccess?.centivCard)
    } else if (obj?.key === ACL_MODULES?.MY_OTHER_COMPANIES) {
      return (user?.client?.viewAccess?.myOtherCompanies || user?.viewAccess?.myOtherCompanies)
    } else {
      return true;
    }
  })
  ACL_LIST = ACL_LIST.map((obj) => {
    let topicsArray = topicsClub[obj?.key] || [];
    if (topicsArray?.length && thirdStepTopics?.length) {
      if (matchArrays(topicsArray, thirdStepTopics)?.length && !obj.access?.includes(ACL_ACCESS_LIST.REQUEST_AUTHORIZER)) {
        obj.access = [...obj.access, ACL_ACCESS_LIST.REQUEST_AUTHORIZER]
      }
    }
    if (thirdStepTopics?.length) {
      if (obj.key === ACL_MODULES.REQUESTS && !obj.access?.includes(ACL_ACCESS_LIST.ALL_REQUEST_AUTHORIZER)) {
        obj.access = [...obj.access, ACL_ACCESS_LIST.ALL_REQUEST_AUTHORIZER]
      }
    }
    return obj
  });
  if (!user?.isParentCompany) {
    ACL_LIST = ACL_LIST.filter(acl => acl.key !== ACL_MODULES.MY_OTHER_COMPANIES);
  }
  if (user?.isClient) {
    return ACL_LIST;
  } else {
    if (user?.accessRights && user?.accessRights?.length) {
      let res = JSON.parse(user?.accessRights);
      return res;
    }
    return [];
  }
};
